import react from 'react'; // eslint-disable-line
import { graphql, useStaticQuery } from 'gatsby';
import { StrapiPage } from '../gatsby/types/strapi-page';

export type AllPages = {
  allStrapiListingPage: {
    edges: {
      node: StrapiPage;
    }[]
  }
}

export const useStrapiListingPages = (
): StrapiPage[] => {
  const { allStrapiListingPage } = useStaticQuery<AllPages>(graphql`
    query {
      allStrapiListingPage(filter: {
        pageSettings: {publishStatus: {eq: true}},
        seoSettings: {metaIndex: {eq: "index"}},
      }) {
        edges {
          node {
            id
            title
            pageSettings {
              id
              slug
              subpath
              language
              publishStatus
              parentPagePath
              parentPageTitle
            }
          }
        }
      }
    }
  `);

  return allStrapiListingPage.edges.map(({ node }) => node);
};

export default useStrapiListingPages;
