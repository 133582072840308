import React from 'react';
import { useTranslation } from 'src/views/misc/localization';
import { Sitemap } from 'src/infrastructure/gatsby/types/sitemap';
import { useStrapiPages } from 'src/infrastructure/hooks/use-strapi-pages';
import {
  useStrapiPagesTranslations,
} from 'src/infrastructure/hooks/use-strapi-pages-translations';
import {
  useStrapiListingPages,
} from 'src/infrastructure/hooks/use-strapi-listing-pages';
import {
  useStrapiListingPagesTranslations,
} from 'src/infrastructure/hooks/use-strapi-listing-pages-translations';
import {
  useStrapiBlogArticles,
} from 'src/infrastructure/hooks/use-strapi-blog-articles';
import {
  useStrapiBlogArticlesTranslations,
} from 'src/infrastructure/hooks/use-strapi-blog-articles-translations';
import {
  useStrapiNewsArticles,
} from 'src/infrastructure/hooks/use-strapi-news-articles';
import {
  useStrapiNewsArticlesTranslations,
} from 'src/infrastructure/hooks/use-strapi-news-articles-translations';

import PagesColumn from './column';
import { Section } from './styles';

const DEFAULT_GROUP_ID = 'Other_links';
const GROUPS = [
  'Solutions',
  'Track',
  'Company',
  'Legal',
  'Support',
  'Other_links',
];

const SitemapSection: React.FC<Sitemap> = ({ sectionId }) => {
  const { t, locale } = useTranslation();

  const strapiPagesEn = useStrapiPages();
  const strapiPagesAr = useStrapiPagesTranslations();
  const strapiPages = locale === 'ar' ? strapiPagesAr : strapiPagesEn;
  const srapiPagesGroups = GROUPS.map((groupId) => {
    const groupPages = strapiPages.filter((page) => {
      const { additionalPageSettings } = page;

      return additionalPageSettings?.sitemapGroup === groupId
        || groupId === DEFAULT_GROUP_ID;
    });

    return { groupId, groupPages };
  });

  const strapiListingPagesEn = useStrapiListingPages();
  const strapiListingPagesAr = useStrapiListingPagesTranslations();
  const strapiListingPages = locale === 'ar'
    ? strapiListingPagesAr : strapiListingPagesEn;

  const strapiBlogArticlesEn = useStrapiBlogArticles();
  const strapiBlogArticlesAr = useStrapiBlogArticlesTranslations();
  const strapiBlogArticles = locale === 'ar'
    ? strapiBlogArticlesAr : strapiBlogArticlesEn;

  const strapiNewsArticlesEn = useStrapiNewsArticles();
  const strapiNewsArticlesAr = useStrapiNewsArticlesTranslations();
  const strapiNewsArticles = locale === 'ar'
    ? strapiNewsArticlesAr : strapiNewsArticlesEn;

  return (
    <Section id={sectionId}>
      {srapiPagesGroups.map(({
        groupId, groupPages,
      }) => groupPages?.length > 0 && (
        <PagesColumn
          locale={locale}
          header={t(groupId)}
          pages={groupPages}
        />
      ))}
      <PagesColumn
        locale={locale}
        header={t('sitemapListngPages')}
        pages={strapiListingPages}
      />
      <PagesColumn
        locale={locale}
        header={t('sitemapBlogArticles')}
        articles={strapiBlogArticles}
        articleParentPath="/blog/"
      />
      <PagesColumn
        locale={locale}
        header={t('sitemapNewsArticles')}
        articles={strapiNewsArticles}
        articleParentPath="/news/"
      />
    </Section>
  );
};

export default SitemapSection;
