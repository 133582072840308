import styled from 'styled-components';
import { black } from '@shipae/components-sandbox/component/colors';
import { fonts, media } from 'src/views/theme';
import SmartLink from 'components/smart-link';

export const Section = styled.section`
  padding: 9rem calc((100% - var(--container-width)) / 2);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  padding: 0 1rem 2rem 0;

  ${ media.md } {
    width: 33.33%;
  }
  ${ media.sm } {
    width: 50%;
  }
  ${ media.xs } {
    width: 100%;
  }
  width: 25%;
`;

export const ColumnHeader = styled.h2`
  ${ fonts.displayxs300 };
  padding: 0 0 1rem 0;
`;

export const Link = styled(SmartLink)`
  display: block;
  ${ fonts.bodys200 };
  color: ${ black() };
  padding: 1rem 0;

  &:hover {
    text-decoration: none;
  }
`;
