import react from 'react'; // eslint-disable-line
import { graphql, useStaticQuery } from 'gatsby';
import { Article } from '../gatsby/types/article';

export type AllPages = {
  allStrapiNewsArticleTranslation: {
    edges: {
      node: Article;
    }[]
  }
}

export const useStrapiNewsArticlesTranslations = (
): Article[] => {
  const { allStrapiNewsArticleTranslation } = useStaticQuery<AllPages>(graphql`
    query {
      allStrapiNewsArticleTranslation(filter: {
        articleSettings: {publishStatus: {eq: true}},
        seoSettings: {metaIndex: {eq: "index"}},
      }) {
        edges {
          node {
            id
            articleSettings {
              id
              slug
              title
              publishStatus
              content
              excerpt
              publishDate
            }
          }
        }
      }
    }
  `);

  return allStrapiNewsArticleTranslation.edges.map(({ node }) => node);
};

export default useStrapiNewsArticlesTranslations;
