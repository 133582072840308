import react from 'react'; // eslint-disable-line
import { graphql, useStaticQuery } from 'gatsby';
import { Article } from '../gatsby/types/article';

export type AllPages = {
  allStrapiBlogArticleTranslation: {
    edges: {
      node: Article;
    }[]
  }
}

export const useStrapiBlogArticlesTranslations = (
): Article[] => {
  const { allStrapiBlogArticleTranslation } = useStaticQuery<AllPages>(graphql`
    query {
      allStrapiBlogArticleTranslation(filter: {
        articleSettings: {publishStatus: {eq: true}},
        seoSettings: {metaIndex: {eq: "index"}},
      }) {
        edges {
          node {
            id
            articleSettings {
              id
              slug
              title
              publishStatus
              content
              excerpt
              publishDate
            }
          }
        }
      }
    }
  `);

  return allStrapiBlogArticleTranslation.edges.map(({ node }) => node);
};

export default useStrapiBlogArticlesTranslations;
