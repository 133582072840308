import { GetLocalizedPathProps } from './types';

export const getLocalizedPath = ({
  locale,
  nullableSubpath,
  slug,
}: GetLocalizedPathProps): string => {
  const subpath = nullableSubpath || '';

  let fullPath = locale === process.env.GATSBY_DEFAULT_LOCALE
    ? `/${ subpath }/${ slug }/`
    : `/${ locale }/${ subpath }/${ slug }/`;
  fullPath = fullPath.replace('///', '/').replace('//', '/');

  return fullPath;
};

export const ensureLowerCase = (
  text: string,
): string => text.toLowerCase();
