import React from 'react';

import { StrapiPage } from 'src/infrastructure/gatsby/types/strapi-page';
import { Article } from 'src/infrastructure/gatsby/types/article';
import {
  ensureLowerCase, getLocalizedPath,
} from 'src/infrastructure/gatsby/node/utils';
import {
  Column, Link, ColumnHeader,
} from './styles';

type Props = {
  header: string,
  locale: string,
  pages?: StrapiPage[],
  articleParentPath?: string,
  articles?: Article[],
};

const PagesColumn: React.FC<Props> = ({
  header, locale, pages, articleParentPath, articles,
}) => (
  <Column>
    <ColumnHeader>{header}</ColumnHeader>
    {pages && pages.map((page) => {
      const { subpath, slug } = page.pageSettings;
      const path = ensureLowerCase(getLocalizedPath({
        locale,
        nullableSubpath: subpath,
        slug,
      }));

      return (
        <Link
          href={path}
          key={page.id}
        >
          {page.title}
        </Link>
      );
    })}
    {articles && articles.map((article) => {
      const { title, slug } = article.articleSettings;
      const path = ensureLowerCase(getLocalizedPath({
        locale,
        nullableSubpath: articleParentPath,
        slug,
      }));

      return (
        <Link
          href={path}
          key={article.id}
        >
          {title}
        </Link>
      );
    })}
  </Column>
);

export default PagesColumn;
